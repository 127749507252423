import Button from "@mui/material/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/theTeam.scss";
//Paths

const TheTeam = () => {
  const navigate = useNavigate();
  return (
    <section className="the-team">
      <div className="the-team-circle show-desktop"></div>
      <div className="the-team-triangle show-desktop"></div>

      <div className="team-container">
        <h2>The Team</h2>
        <div className="team-sliders">
          <div className="team-slide">
            <div className="team-member-image">
              <div className="team-member-image-container"></div>
            </div>
            <div className="team-member-description">
              <p>
                After spending decades in the social sector working on behalf of
                foundations, nonprofits, businesses, and government agencies, a
                team of changemakers led by
                <a href="https://www.linkedin.com/in/yuemily/">Emily Yu</a>
                recognized that despite the combined efforts of the social
                sector, most initiatives result in limited and incremental
                change—if there was any change at all.
              </p>
              <p>
                In order to address society's most pressing challenges we must
                foster transformational, systemic change... the type of change
                that affects legislative policies, organizational policies,
                funding streams, and norms that aim to maintain the status quo.
              </p>
              <p>
                To achieve this, our team believes we must start by changing how
                change is made. A key first step is helping changemakers review
                their content at scale and through a systems lens. With this
                critical first step, changemakers can position themselves to
                make more effective and evidence-based decisions that have the
                potential to transform whole systems.
              </p>
            </div>
          </div>
        </div>
        <Button
          id="the-team-get-in-touch-btn"
          variant="contained"
          onClick={() => navigate("/contactus")}
        >
          Get In Touch
        </Button>
      </div>
    </section>
  );
};

export default TheTeam;
