import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/header.scss";
import { useWindowWidthAndHeight } from "./CustomHooks";
import Navbar from "./Navbar";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import { CloseOutlined } from "@mui/icons-material";

//Paths
const logoPath = "/img/AI_Priori-logo.svg";
const logoMobilePath = "/img/AI_Priori_compact-logo.svg";
const navItems = [
  { label: "Home", path: "/" },
  {
    label: "About",
    path: "/about",
    childs: [
      { label: "Purpose", path: "/" },
      { label: "Safety", path: "/" },
      { label: "FAQs", path: "/" },
      { label: "Team", path: "/" },
    ],
  },
  { label: "Partners", path: "/partners" },
  { label: "Resources", path: "/resources" },
  { label: "Pricing", path: "/pricing" },
];

const HomepageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // use our custom hook to get the the window size
  const [width, height] = useWindowWidthAndHeight();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const checkCurrentClass = (path) => {
    return location.pathname === path ? "active-item" : "";
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <header className="homepage">
      <div className="promo-banner">
        <button
          id="header-free-trial-btn"
          onClick={() => navigate("/joinus")}
          className="btn link-btn"
        >
          Sign up for a free trial today!
        </button>
      </div>
      <div className="header-inner">
        <img
          smooth="true"
          className="Homepage-Header-logo"
          src={logoPath}
          alt=""
          onClick={() => navigate("/")}
        />

        <img
          smooth="true"
          className="mobile-logo"
          src={logoMobilePath}
          alt=""
          onClick={() => navigate("/")}
        />

        <div className="mobile-nav">
          <Button
            id="header-log-in-btn"
            onClick={() => (window.location.href = "https://app.aipriori.com")}
            variant="contained"
          >
            Log in
          </Button>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Navbar />
      </div>

      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <CloseOutlined />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navItems.map((navItem) => (
            <ListItem key={navItem.label} disablePadding>
              <ListItemButton
                onClick={() => navigate(navItem.path)}
                selected={checkCurrentClass(navItem.path)}
              >
                <ListItemText primary={navItem.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List style={{ marginTop: `auto` }}>
          <ListItem>
            <ListItemButton
              onClick={() =>
                (window.location.href = "https://app.aipriori.com")
              }
            >
              <ListItemText>Sign up for a free trial today!</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </header>
  );
};

export default HomepageHeader;
