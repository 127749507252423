import '../App.css';
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from '../components/HomepageFooter';
import LoginPage from '../components/Login';
import { useEffect } from 'react';

function Login() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <HomepageHeader />
      <div className='maxWidth'>
        <LoginPage />
      </div>
      <HomepageFooter />
    </div>
  );
}

export default Login;
