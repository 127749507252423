import React from "react";
import "../styles/infoToInsights.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
//Paths
const docImg = "/img/AdobeStock_631236355.jpg";

const InfoToInsights = () => {
  const navigate = useNavigate();
  return (
    <div className="infoToInsights">
      <div className="infoToInsights-img">
        <img className="imgborder" smooth="true" src={docImg} alt=""></img>
      </div>
      <div className="infoToInsights-content">
        <h2>Turn Information into Insights</h2>
        <p>
          There’s a lot to consider when it comes to identifying the right AI
          strategy and tools to help your organization maximize its impact. AI
          PRIORI Labs is here to help. Serving as your partner to help
          strategize, experiment, and/or build the AI solution that’s right for
          you, AI PRIORI® Labs can help you harness AI technology in ways that
          align with your mission, values, and the community you serve.
        </p>
        <p>
          With a team consisting of former program officers, nonprofit
          practitioners, data scientists, grantees, computer programmers, social
          entrepreneurs, and community advocates, we’ve experienced challenges
          similar to what you’re facing—and know how AI can help address them.
          We are uniquely suited to help you with your exploration of AI in the
          social sector.
        </p>
        <p>AI PRIORI Labs is here to help you:</p>
        <ul>
          <li>Develop an AI strategy for your organization</li>
          <li>Implement AI-rooted experiments and proof-of-concept projects</li>
          <li>Train custom computer models</li>
          <li>Advise on technical needs for adoption of AI tools</li>
          <li>
            Serve as an extension of your team to help you ideate and implement
            AI tools within your organization
          </li>
        </ul>
        <p>
          We've helped dozens of nonprofits and foundations get started on their
          AI journey. For some, the conversation starts with the curiosity to
          learn what's possible, while others have a specific idea in mind. No
          matter where you are on your path, we’re ready to help you increase
          your impact.
        </p>
        <br></br>
        <Button
          id="info-to-insights-lets-talk-btn"
          variant="contained"
          onClick={() => navigate("/contactus")}
        >
          Let's Talk
        </Button>
      </div>
    </div>
  );
};

export default InfoToInsights;
