import { Button, TextField, ThemeProvider, createTheme } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import "../styles/contactUs.scss";
import GetCurrentDate from "./utils/GetCurrentDate";
import GuidGenerator from "./utils/GuidGenerator";

//Paths
const backgroundImg = "/img/aipBackgroundImg.jpg";
const API_KEY = process.env.REACT_APP_EAR_API_KEY;
const API_URL =
  "https://9uzejq2rj3.execute-api.us-east-1.amazonaws.com/prod/message";

const ContactForm = () => {
  const [post, setPost] = React.useState(null);
  const [orginization, setorganization] = useState("");
  const [email, setemail] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  let handleSubmit = async (e) => {
    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Please enter a valid email address.");
      e.preventDefault();
      return;
    }

    var uuid = GuidGenerator();
    var date = GetCurrentDate();
    e.preventDefault();
    axios
      .post(
        API_URL,
        {
          messageId: uuid,
          organization: orginization,
          firstname: firstname,
          lastname: lastname,
          email: email,
          message: message,
          messagedate: date,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setfirstname("");
          setemail("");
          setlastname("");
          setorganization("");
          setMessage("");
          setemail("");
          setPost("");
          alert("Thank you for contacting AI PRIORI®! We'll be in touch soon.");
        }
      })
      .catch((res) => {
        if (res.status != 200) {
          alert(
            "Something went wrong. Please contact us if you continue to have issues."
          );
        }
      });
  };

  const darkFormTheme = createTheme({
    overrides: {
      MuiInput: {
        underline: {
          "&:after": {
            backgroundColor: "#FF0000",
          },
        },
      },
    },
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            padding: "8px 16px",
          },
        },
        underline: {
          "&:after": {
            borderBottom: `2px solid #FFFFFF`,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            padding: "8px 16px",
            color: "rgba(255, 255, 255, 0.70)",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: "#ffffff",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            padding: "10px 24px",
            fontSize: "14px",
          },
        },
        variants: [
          {
            props: { variant: "contained" },
            style: {
              color: "#47246B",
            },
          },
        ],
      },
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#ffffff",
      },
    },
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="contact-form">
      <div className="contact-form-content">
        <h2>Contact Us</h2>
        <ThemeProvider theme={darkFormTheme}>
          <form
            onSubmit={handleSubmit}
            name="contact-form-form"
            className="contact-form-content"
          >
            <fieldset className="contact-form-content">
              <TextField
                label="Organization"
                variant="standard"
                name="orginization"
                value={orginization}
                required
                onChange={(e) => setorganization(e.target.value)}
              />
              <TextField
                label="First Name"
                variant="standard"
                name="firstName"
                value={firstname}
                required
                onChange={(e) => setfirstname(e.target.value)}
              />
              <TextField
                label="Last Name"
                variant="standard"
                name="lastName"
                value={lastname}
                required
                onChange={(e) => setlastname(e.target.value)}
              />
              <TextField
                label="Email"
                variant="standard"
                name="email"
                value={email}
                required
                error={!!emailError}
                helperText={emailErrorText}
                onChange={(e) => {
                  setemail(e.target.value);
                  setEmailError(false);
                  setEmailErrorText("");
                }}
              />
              <TextField
                multiline
                rows={4}
                label="Message"
                variant="standard"
                name="message"
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)}
              />
            </fieldset>
            <Button id="contact-us-send-btn" type="submit" variant="contained">
              Send
            </Button>
          </form>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default ContactForm;
