//Navbar.js
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import IconArrowForward from "./icons/IconArrowForward";

const navItems = [
  { label: "Home", path: "/" },
  {
    label: "About",
    path: "/about",
    childs: [
      { label: "Purpose", path: "/" },
      { label: "Safety", path: "/" },
      { label: "FAQs", path: "/" },
      { label: "Team", path: "/" },
    ],
  },
  { label: "Partners", path: "/partners" },
  { label: "Labs", path: "/labs" },
  { label: "Resources", path: "/resources" },
  //Removing pricing link in header until ready
  //{ label: "Pricing", path: "/pricing" },
];
export default function Navbar(props) {
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const checkCurrentClass = (path) => {
    return location.pathname === path ? "active-item" : "";
  };
  return (
    <nav className={`nav-big ${props.className}`}>
      <div className="link-grp">
        {navItems.map((navItem) => (
          <Link
            to={navItem.label}
            smooth="true"
            className={`nav-big-link ${checkCurrentClass(navItem.path)}`}
            onClick={() => navigate(navItem.path)}
          >
            {navItem.label}
          </Link>
        ))}
      </div>
      <div className="button-grp">
        <Button
          id="nav-bar-join-us-btn"
          onClick={() => navigate("/joinus")}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          variant="outlined"
          endIcon={<IconArrowForward />}
        >
          Join Us
        </Button>
        <Button
          onClick={() => (window.location.href = "https://app.aipriori.com")}
          className="Login-Button"
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          variant="contained"
        >
          Log in
        </Button>
      </div>
    </nav>
  );
}
