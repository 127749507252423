import React from 'react';
import '../styles/learningHub.css';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-scroll';
//Paths

const LearningHub = () => {
    const navigate = useNavigate();
    return (
        <div className="learning-hub">
            <div className='learning-hub-content-container'>
                <h2 className='learning-hub-content'>Learning Hub</h2>
                <p className='learning-hub-content-sub'>Check out a few of the resources on systems change, knowledge management, and social change that have inspired us.</p>
                <p className='learning-hub-content'><a href='https://drive.google.com/file/d/1FjmH9TIYs3g_BFe1079QQrybaGtr8QJD/view?usp=sharing'>Guide to Developing Your Organization’s AI Strategy by AI PRIORI and DoTank <b>(NEW)</b></a></p>
                <p className='learning-hub-content'><a href='https://www.chelseagreen.com/product/thinking-in-systems/'>Thinking in Systems by Donella H. Meadows</a></p>
                <p className='learning-hub-content'><a href='https://nonprofitquarterly.org/ideas-arrangements-effects-systems-design-and-social-justice/'>Ideas Arrangement Effects: Systems Design and Social Justice</a></p>
                <p className='learning-hub-content'><a href='https://www.ashoka.org/en-us/story/systems-change-high-level-summary'>Systems Change: A High-Level Summary from Ashoka</a></p>
                <p className='learning-hub-content'><a href='https://ssir.org/articles/entry/fostering_systems_change'>Fostering Systems Change in Stanford Social Innovation Review</a></p>
                <p className='learning-hub-content'><a href='https://blog.acumenacademy.org/systems-practice'>Using Systems Practice to Tackle Difficult Problems in Complex Environments by Acumen</a></p>
                <p className='learning-hub-content'><a href='https://debeaumont.org/news/2022/de-beaumont-foundation-announces-launch-of-leading-systems-change-in-public-health-a-field-guide-for-practitioners/'>Leading Systems Change in Public Health from Springer Publishing</a></p>
            </div>
        </div>
    )
}

export default LearningHub;