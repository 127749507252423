import React from 'react';
import '../styles/generic-page.scss';

const TermsOfService = () => {
    const termsOfServiceUrl = "https://d1iikonv94kyk.cloudfront.net/AIP_Terms_of_Service.pdf";

    return (
        <div className="pdf-page-container">
            <object data={termsOfServiceUrl} type="application/pdf" width="100%" height="100%">
            </object>
        </div>
    )
}

export default TermsOfService;