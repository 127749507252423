import '../App.css';
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from '../components/HomepageFooter';
import ContactForm from '../components/ContactForm';
import { useEffect } from 'react';

function JoinUs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <HomepageHeader />
      <div className='maxWidth  page-margin'>
        <ContactForm />
      </div>
      <HomepageFooter />
    </div>

  );
}

export default JoinUs;
