import "../styles/login.css";
import { useNavigate } from "react-router-dom";

//Paths
const backgroundImg = "/img/aipBackgroundImg.jpg";
const Login = () => {
  const navigate = useNavigate();
  return (
    <div className="login">
      <img smooth="true" className="login" src={backgroundImg} alt=""></img>
      <div className="login-content">
        <h2>Login</h2>
        <form className="login-content">
          <fieldset className="login-content">
            <label className="login-content">
              <p className="login-content">Username</p>
              <input className="login-content" name="username" />
            </label>
            <label className="login-content">
              <p className="login-content">Password</p>
              <input className="login-content" name="password" />
            </label>
          </fieldset>
          <button
            id="login-login-btn"
            className="login-content"
            onClick={() => navigate("/joinus")}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
