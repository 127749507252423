import React from "react";
import "../styles/callToAction.scss";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
//Paths
const docImg = "/img/whatIsAIP_photo.png";

const CallToAction = () => {
  const navigate = useNavigate();
  return (
    <section className="call-to-action">
      <div className="call-to-action-content">
        <div className="text-container">
          <h2>
            What is <b>AI PRIORI</b>?
          </h2>
          <img
            className="show-mobile"
            smooth="true"
            src={docImg}
            alt="What is AI PRIORI"
          ></img>
          <p>
            Created by, for, and with changemakers, AI PRIORI is designed to
            help social sector leaders at nonprofits, foundations, government
            institutions, and communities review their content at scale. WIth AI
            PRIORI's customizable Lenses, you can seamlessly integrate your own
            definitions, frameworks, and concepts into your queries so you
            always get the most relevant responses. Whether you have 2 pages or
            2,000, AI PRIORI is ready to help you reclaim your time, save on
            research expenses, and increase your impact.
          </p>
          <Button
            id="call-to-action-learn-more-btn"
            variant="contained"
            onClick={() => navigate("/about")}
          >
            Learn More
          </Button>
        </div>
        <div className="image-container show-desktop">
          <img smooth="true" src={docImg} alt=""></img>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
