import React, { Component } from "react";
import "../styles/mailchimpForm.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material";

const mailchimpTheme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          backgroundColor: "#FF0000",
        },
      },
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          padding: "8px 16px",
        },
      },
      underline: {
        "&:after": {
          borderBottom: `2px solid #FFFFFF`,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: "8px 16px",
          color: "rgba(255, 255, 255, 0.70)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "#ffffff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: "10px 24px",
          fontSize: "14px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "#47246B",
          },
        },
      ],
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
  },
});

class MailChimpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: false,
      emailErrorText: "",
    };
    this.validateEmail = this.validateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (e) => {
    if (this.state.email === "") {
      this.setState({
        emailError: true,
        emailErrorText: "This field is required.",
      });
      e.preventDefault();
    }
    if (!this.validateEmail(this.state.email)) {
      this.setState({
        emailError: true,
        emailErrorText: "Please enter a valid email address.",
      });
      e.preventDefault();
    }
    return true;
  };

  validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.type = "text/javascript";
    document.body.appendChild(script);

    script.onload = () => {
      window.fnames = ["EMAIL", "FNAME", "LNAME", "ADDRESS", "PHONE"];
      window.ftypes = ["email", "text", "text", "address", "phone"];
      var $mcj = window.jQuery.noConflict(true);
    };
  }

  render() {
    return (
      <ThemeProvider theme={mailchimpTheme}>
        <section className="mailchimp-form">
          <div>
            <div id="mc_embed_signup">
              <h2>Subscribe for updates from AI PRIORI®</h2>
              <form
                action="https://aipriori.us13.list-manage.com/subscribe/post?u=11319f7cf317270ed33debe15&amp;id=a71864b2ae&amp;f_id=009135e2f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                onSubmit={this.handleSubmit}
              >
                <TextField
                  label="Email Address*"
                  variant="standard"
                  name="EMAIL"
                  id="mce-EMAIL"
                  className="required email"
                  type="email"
                  value={this.state.email}
                  error={!!this.state.emailError}
                  helperText={this.state.emailErrorText}
                  onChange={(e) =>
                    this.setState({
                      email: e.target.value,
                      emailError: false,
                      emailErrorText: "",
                    })
                  }
                />

                <TextField
                  label="First Name"
                  variant="standard"
                  name="FNAME"
                  id="mce-FNAME"
                />

                <TextField
                  label="Last Name"
                  variant="standard"
                  name="LNAME"
                  id="mce-LNAME"
                />

                <Button
                  id="mailchimp-subscribe-btn"
                  variant="contained"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Subscribe
                </Button>

                <div id="mc_embed_signup_scroll">
                  <div id="mce-responses" className="clear foot">
                    <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: "none" }}
                    ></div>
                    <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div
                    aria-hidden="true"
                    style={{ position: "absolute", left: "-5000px" }}
                  >
                    <input
                      type="text"
                      name="b_11319f7cf317270ed33debe15_a71864b2ae"
                      tabIndex="-1"
                    />
                  </div>

                  <div className="indicates-required">
                    <span className="asterisk">*</span> required
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </ThemeProvider>
    );
  }
}

export default MailChimpForm;
