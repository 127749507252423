import { useState } from "react";
import { useEffect } from "react";
import "../App.css";
import HomepageFooter from "../components/HomepageFooter";
import HomepageHeader from "../components/HomepageHeader";
import MailChimpForm from "../components/MailChimpForm";
import PageHeroSection from "../components/PageHeroSection";
import PricingPlans from "../components/PricingPlans";
import RequestAccess from "../components/RequestAccess";
import BeforeLeavingPopup from "../components/BeforeLeavingPopup";

function Pricing() {
  const [leaving, setLeaving] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    window.onbeforeunload = () => {
      setLeaving(true);
      return true;
    };
  }, []);

  return (
    <div>
      {leaving && <BeforeLeavingPopup closePopup={() => setLeaving(false)} />}

      <HomepageHeader />
      <div className="maxWidth page-margin">
        <PageHeroSection title="Pricing" centered className="plans-hero" />
        <PricingPlans />

        <RequestAccess />
        <MailChimpForm />
      </div>
      <HomepageFooter />
    </div>
  );
}

export default Pricing;
