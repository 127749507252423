import React from "react";
import "../styles/dataDrivenInsights.scss";

//Paths
const serviceImg = "/img/AdobeStock_714304109.jpg";

const DataDrivenInsights = () => {
  return (
    <section className="data-driven-insights">
      <div className="image-container">
        <img className="dd_img" smooth="true" src={serviceImg} alt=""></img>
      </div>
      <div className="text-container">
        <div className="data-driven-insights-content">
          <h2>
            <b>3 Steps</b> to Data-Driven Insights
          </h2>
          <div className="data-driven-insights-content-bullet">
            <div className="data-driven-insights-red-circle">01</div>
            <div className="data-driven-insights-content-text">
              Upload Your Documents
            </div>
          </div>
          <div className="data-driven-insights-content-bullet">
            <div className="data-driven-insights-red-circle">02</div>
            <div className="data-driven-insights-content-text">
            Select Your Lenses
            </div>
          </div>
          <div className="data-driven-insights-content-bullet-2-text">
            <p>Create Your Own Lenses:</p>
            <ul className="dd_bullets">
              <br></br>
              <li>Mission and Goals</li>
              <li>Definitions</li>
              <li>Personas</li>
              <li>And More…</li>
            </ul>
            <p>
            Or Use One of Our Exclusive Community-created Lenses 
            </p>
          </div>
          <div className="data-driven-insights-content-bullet-3">
            <div className="data-driven-insights-red-circle">03</div>
            <div className="data-driven-insights-content-text">
            Ask a Question or Assign a Task
            </div>
            <br></br>
            <p>Receive a tailored response</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataDrivenInsights;
