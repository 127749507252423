import React from "react";
import "../styles/dataPartner.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
//Paths
const docImg = "/img/AdobeStock_349211890.jpg";

const ChangeChange = () => {
  const navigate = useNavigate();
  return (
    <div className="dataPartner">
      <div className="dataPartner-img">
        <img className="imgborder" smooth="true" src={docImg} alt=""></img>
      </div>
      <div className="dataPartner-content">
        <h2>Become a Data Partner</h2>
        <p>Ready to put AI to work using your data?</p>
        <p>
          AI PRIORI® is looking for organizations interested in using AI
          to leverage their data and experience for greater social impact.
          Perhaps you have a data set ready to experiment with? Or maybe you are
          curious about AI, but don't know where to start?
        </p>
        <p>
          As a Data Partner, we'll collaborate with your organization to
          understand what is possible. You'll choose what to share with us and
          ultimately help shape the data that power AI PRIORI.
        </p>
        <br></br>
        <Button
          id="data-partners-connect-with-us-btn"
          variant="contained"
          onClick={() => navigate("/contactus")}
        >
          Connect With Us
        </Button>
      </div>
    </div>
  );
};

export default ChangeChange;
