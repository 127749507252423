import React from "react";

import "../styles/aiPrioriDiff.scss";

const AIPrioriDiff = () => {
  const dataCuration = "/img/DataCuration.png";
  const modelTraining = "/img/modeltraining.png";
  const userExploration = "/img/UserExploration2.png";
  const insightDiscovery = "/img/insightdiscovery2.png";

  return (
    <section className="aip-diff dark-bg">
      <div className="aip-diff-circle show-desktop"></div>
      <div className="aip-diff-black-triangle show-desktop"></div>
      <div className="aip-diff-red-triangle show-desktop"></div>

      <div className="aip-diff-card-grp">
        <h2 className="aip-diff-title small-title">How AI PRIORI Works</h2>
        <div className="aip-diff-card gray-variant">
          <div className="heading">
            <div className="api-diff-card-icon">
              <img smooth="true" src={userExploration} alt=""></img>
            </div>
            <h3>Designed for the Social Sector</h3>
          </div>
          <p>
            Putting the public sector's needs first. The platform is built with,
            for, and by changemakers like you.
          </p>
        </div>
        <div className="aip-diff-card gray-variant">
          <div className="heading">
            <div className="api-diff-card-icon">
              <img smooth="true" src={modelTraining} alt=""></img>
            </div>
            <h3>Tailored Responses</h3>
          </div>
          <p>
            Seamlessly integrate your own definitions, frameworks, and concepts
            into your queries so you always get the most relevant responses.
          </p>
        </div>
        <div className="aip-diff-card gray-variant">
          <div className="heading">
            <div className="api-diff-card-icon">
              <img smooth="true" src={dataCuration} alt=""></img>
            </div>
            <h3>Data Curation</h3>
          </div>
          <p>
            With a community-centered approach, AI PRIORI partners with
            community members and issue area experts to create original data
            sets on the social sector's most pressing challenges. Apply these
            exclusive lenses to your queries and watch as AI PRIORI generates
            new and diverse insights from across all your documents.
          </p>
        </div>
        <div className="aip-diff-card gray-variant">
          <div className="heading">
            <div className="api-diff-card-icon">
              <img smooth="true" src={insightDiscovery} alt=""></img>
            </div>
            <h3>Insight Discovery</h3>
          </div>
          <p>
            A research, brainstorm, and editing partner all in one. Simply
            upload the documents you want to review and ask AI PRIORI to
            generate a response to your question(s). With AI PRIORI,
            changemakers can augment their own knowledge and experiences with
            critical data from across all their documents in just minutes.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AIPrioriDiff;
