import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';

import { createTheme, ThemeProvider } from "@mui/material";
import About from './routes/About';
import ContactUs from './routes/ContactUs';
import Home from './routes/Home';
import JoinUs from './routes/JoinUs';
import Login from './routes/Login';
import Partners from './routes/partners';
import Pricing from './routes/Pricing';
import PrivacyPolicy from './routes/PrivacyPolicy';
import Resources from './routes/Resources';
import TermsOfService from './routes/TermsOfService';
import Labs from "./routes/Labs";


const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          padding: '8px 16px'
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: '8px 16px'
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          borderColor: '#47246B'
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: "10px 24px",
          fontSize: "14px"
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: 'rgba(0, 0, 0, 0.54)',
            borderColor: "rgba(0, 0, 0, 0.54)",
            '&:hover': {
              color: "#47246B",
           },
          },
        }
      ],
    },

  },
  palette: {
    primary: {
      main: '#47246B',
    },
  },
});

function App() {
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/resources" element={<Resources />} />
            <Route exact path="/partners" element={<Partners />} />
            <Route exact path="/labs" element={<Labs/>} />
            <Route exact path="/contactus" element={<ContactUs />} />
            <Route exact path="/joinus" element={<JoinUs />} />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/terms" element={<TermsOfService />} />
          </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
