import '../App.css';
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from '../components/HomepageFooter';
import ChangeChange from '../components/ChangeChange';
import HowItWorks from '../components/HowItWorks';
import TheTeam from '../components/TheTeam';
import { useEffect } from 'react';
import PageHeroSection from '../components/PageHeroSection';
import RequestAccess from '../components/RequestAccess';
import MailChimpForm from '../components/MailChimpForm';

function About() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div >
      <HomepageHeader />
      <div className='maxWidth page-margin'>
        <PageHeroSection title="Changing How Change is Made" />
        <ChangeChange />
        <HowItWorks />
        <TheTeam />
        <RequestAccess />
        <MailChimpForm />
      </div>
      <HomepageFooter />
    </div>
  );
}

export default About;
