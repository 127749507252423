import '../App.css';
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from '../components/HomepageFooter';
import MeetAnna from '../components/MeetAnna';
import LearningHub from '../components/LearningHub';
import BeFeatured from '../components/BeFeatured';
import InfoOverload from '../components/InfoOverload';
import ClosingGap from '../components/ClosingGap';
import { useEffect } from 'react';

function Resources() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <HomepageHeader />
      <div className='maxWidth'>
        <InfoOverload />
        <LearningHub />
        <MeetAnna />
        <BeFeatured />
        <ClosingGap />
      </div>
      <HomepageFooter />
    </div>
  );
}

export default Resources;
