import React from "react";
import "../styles/changeChange.scss";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
//Paths
const docImg = "/img/AdobeStock_566280988.jpg";

const ChangeChange = () => {
  const navigate = useNavigate();
  return (
    <section className="changeChange">
      <div className="change-change-container">
        <div className="changeChange-content">
          <h3>
            AI-powered software specially trained to help leaders at
            foundations, nonprofits, government institutions, and communities
            easily find specific concepts, themes, and insights from across
            their documents.
          </h3>
          <section>
            <p>
              Navigating complex social issues requires public sector leaders to
              take into account large amounts of information as they seek to
              create comprehensive solutions. However, there’s simply too much
              to consume—resulting in knowledge gaps that reduce impact and
              efficiency.
            </p>
            <section>
              <p>
                WIth AI PRIORI®, simply upload your documents for context and
                share the questions or tasks you want the platform to explore
                for you.
              </p>
            </section>
            <section>
              <p>Examples of how AI PRIORI is helping users include:</p>
              <ul>
                <li>Identifying themes across hundreds of grant reports</li>
                <li>Summarizing meeting transcripts</li>
                <li>
                  Reviewing data to jumpstart evaluation and learning efforts
                </li>
                <li>
                  Synthesizing outcomes to articulate organizational impact
                </li>
                <li>And more...</li>
              </ul>
            </section>
          </section>
          <Button
            id="change-change-request-early-access-btn"
            variant="contained"
            onClick={() => navigate("/joinus")}
          >
            Request Early Access
          </Button>
        </div>
        <div className="changeChange-image">
          <img
            className="imgborder changeChange"
            smooth="true"
            src={docImg}
            alt=""
          ></img>
        </div>
      </div>
    </section>
  );
};

export default ChangeChange;
