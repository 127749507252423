//Navbar.js
import Button from "@mui/material/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/pricing-plans.scss";

export default function PricingPlans() {
  const navigate = useNavigate();
  return (
    <section className="pricing-plans">
      <div className="section-container">
        <div className="plans-container">
          <div className="plan-card">
            <div className="icon-section">
              <img src="/img/icons/icon-plan-1.svg" alt="Do-Gooder Plan" />
            </div>
            <div className="plan-name">
              <h3>Do-GOODER</h3>
              <span className="price">$35</span>
              <span className="concurrency">per month</span>
            </div>
            <div className="plan-description">
              <ul>
                <li>Individual User</li>
                <li>Custom Lenses</li>
                <li>Brainstorming Partner</li>
              </ul>
            </div>
            <div className="plan-cta">
              <Button
                id="pricing-do-gooder-sign-up-btn"
                variant="contained"
                onClick={() => navigate("/joinus")}
              >
                Sign up
              </Button>
            </div>
          </div>
          <div className="plan-card">
            <div className="icon-section">
              <img src="/img/icons/icon-plan-2.svg" alt="Changemaker" />
            </div>
            <div className="plan-name">
              <h3>Changemaker</h3>
              <span className="price">$150</span>
              <span className="concurrency">per month</span>
            </div>
            <div className="plan-description">
              <p>Everything on DO-GOODER, plus</p>
              <ul>
                <li>5 Licenses</li>
                <li>Access to skills Webinars</li>
                <li>Early Access to Features</li>
              </ul>
            </div>
            <div className="plan-cta">
              <Button
                id="pricing-changemaker-sign-up-btn"
                variant="contained"
                onClick={() => navigate("/joinus")}
              >
                Sign up
              </Button>
            </div>
          </div>
          <div className="plan-card">
            <div className="icon-section">
              <img src="/img/icons/icon-plan-3.svg" alt="Catalyst" />
            </div>
            <div className="plan-name">
              <h3>Catalyst</h3>
              <span className="price">Enterprise</span>
            </div>
            <div className="plan-description">
              <p>Everything on Changemakers, plus</p>
              <ul>
                <li>1:1 Consultation</li>
                <li>Custom Models</li>
                <li>Team Trainings</li>
              </ul>
            </div>
            <div className="plan-cta">
              <Button
                id="pricing-catalyst-sign-up-btn"
                variant="contained"
                onClick={() => navigate("/joinus")}
              >
                Sign up
              </Button>
            </div>
          </div>
        </div>
        <div className="what-is">
          <h2>What is AI PRIORI®?</h2>
          <p>
            Created by, for, and with changemakers, AI PRIORI is designed to
            help social sector leaders at nonprofits, foundations, government
            institutions, and communities review their content at scale. Whether
            you have 10 documents or 10,000, AI PRIORI is trained to find the
            most relevant excerpts, positioning users to better understand key
            concepts and answer pivotal questions, such as “what helped a
            program succeed?” or “what was an obstacle?"
          </p>
        </div>
      </div>
    </section>
  );
}
