import '../App.css';
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from '../components/HomepageFooter';
import TermsOfServiceContent from '../components/TermsOfService';

import { useEffect } from 'react';

function PrivacyPolicy() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div >
            <HomepageHeader />
            <div className='maxWidth page-margin'>
                <TermsOfServiceContent />
            </div>
            <HomepageFooter />
        </div>
    );
}

export default PrivacyPolicy;
