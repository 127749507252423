import React, { useState } from "react";
import "../styles/heroSection.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconGoogle from "./icons/IconGoogle";

const HeroSection = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubmit = async () => {
    await loginWithRedirect({
      appState: { returnTo: "/" },
      authorizationParams: {
        login_hint: email,
      },
    });
  };
  return (
    <section className="hero-section">
      <div className="hero-content">
        <div className="featured-section">
          <h2>
            Find What <b>Matters</b>
          </h2>
          <p>
            Introducing AI PRIORI: AI-powered software that helps you identify
            the most relevant insights from across all your documents–so you can
            make more effective decisions for greater impact.
          </p>
        </div>

        <div className="form-section">
          <h2>Chat with AI PRIORI®</h2>
          <div className="show-desktop">
            <Box component="form" noValidate autoComplete="off">
              <TextField
                label="Email Address"
                variant="standard"
                value={email}
                onChange={handleChange}
                type="email"
              />
              <Button
                onClick={handleSubmit}
                id="hero-continue-with-email-btn"
                variant="contained"
              >
                Continue with Email
              </Button>
              <span>or</span>
              {/** 
              <Button
                id="hero-continue-with-google-btn"
                variant="outlined"
                startIcon={<IconGoogle />}
              >
                Continue with google
              </Button>
*/}
              <Button
                id="hero-continue-with-google-btn"
                variant="outlined"
                onClick={() => navigate("/joinus")}
              >
                Sign Up
              </Button>
            </Box>
            <p className="disclaimer">
              AI PRIORI is currently in open beta and usage of the platform may
              be limited at times.
            </p>
          </div>
          <div className="show-mobile">
            <Button id="hero-start-now-btn" variant="contained">
              Start Now
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
