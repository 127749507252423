import React from "react";
import "../styles/benefitsForDataPartners.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const AIPrioriDiff = () => {
  const navigate = useNavigate();
  return (
    <div className="benefits-for-data-partners">
      <div className="background-shapes">
        <div className="benefits-for-data-partners-circle show-desktop"></div>
        <div className="benefits-for-data-partners-black-triangle show-desktop"></div>
        <div className="benefits-for-data-partners-red-triangle show-desktop"></div>
      </div>
      <h2 className="benefits-for-data-partners-title">
        Benefits for Data Partners
      </h2>
      <div className="benefits-for-data-partners-card-grp">
        <div className="benefits-for-data-partners-card">
          <div className="benefits-for-data-partners-card-icon">
            <img smooth="true" src={"/img/influence.png"} alt=""></img>
          </div>
          <h3>IMPACT</h3>
          <p>
            Your data will directly inform and influence those using AI PRIORI.
            Gain access to exclusive user metrics to quantify your extended
            impact.
          </p>
        </div>
        <div className="benefits-for-data-partners-card">
          <div className="benefits-for-data-partners-card-icon">
            <img smooth="true" src={"/img/Training.png"} alt=""></img>
          </div>
          <h3>AI TRAINING</h3>
          <p>
            Be a part of the computer model training, testing, and auditing
            process (no technical experience needed).
          </p>
        </div>
        <div className="benefits-for-data-partners-card benefits-for-data-partners-card-lg">
          <div className="benefits-for-data-partners-card-icon">
            <img smooth="true" src={"/img/Compensation.png"} alt=""></img>
          </div>
          <h3>COMPENSATION</h3>
          <p>
            Your organization and/or grantees are eligible to receive financial
            compensation for use of the data.
          </p>
        </div>
        <div className="benefits-for-data-partners-card">
          <div className="benefits-for-data-partners-card-icon">
            <img smooth="true" src={"/img/safety.png"} alt=""></img>
          </div>
          <h3>DATA PROTECTION</h3>
          <p>
            You control what data we access, while we provide full transparency
            into how the data will be used. The raw data and documents you share
            will never be accessible to AI PRIORI users.
          </p>
        </div>
      </div>
      <div className="bottom">
        <br></br>
        <Button
          id="benefits-partners-lets-collaborate-btn"
          variant="contained"
          onClick={() => navigate("/contactus")}
        >
          Let's Collaborate
        </Button>

        <div
          style={{
            fontSize: "x-small",
            color: "lightgray",
            textAlign: "center",
            marginTop: "10rem",
          }}
        >
          <p>Photo Credits</p>
          <p>
            Influence Icon{" "}
            <a
              href="https://www.flaticon.com/free-icons/influence"
              title="Influence icons created by noomtah - Flaticon"
              target="_blank"
              rel="noopener noreferrer"
              className="gray-link"
            >
              designed by Noomtah
            </a>{" "}
            from Flaticon
          </p>
          <p>
            Practice Icon{" "}
            <a
              href="https://www.flaticon.com/free-icons/experience"
              title="Experience icons created by Freepik - Flaticon"
              target="_blank"
              rel="noopener noreferrer"
              className="gray-link"
            >
              designed by Freepik
            </a>{" "}
            from Flaticon
          </p>
          <p>
            Refund Icon{" "}
            <a
              href="https://www.flaticon.com/free-icons/refund"
              title="Refund icons created by LAFS - Flaticon"
              target="_blank"
              rel="noopener noreferrer"
              className="gray-link"
            >
              designed by LFAS
            </a>{" "}
            from Flaticon
          </p>
          <p>
            Safety Icon{" "}
            <a
              href="https://www.flaticon.com/free-icons/privacy"
              title="Privacy icons created by kliwir art - Flaticon"
              target="_blank"
              rel="noopener noreferrer"
              className="gray-link"
            >
              designed by kliwir art
            </a>{" "}
            from Flaticon
          </p>
        </div>
      </div>
    </div>
  );
};

export default AIPrioriDiff;
