import '../App.css';
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from '../components/HomepageFooter';
import HeroSection from '../components/HeroSection';
import CallToAction from '../components/CallToAction';
import DataDrivenInsights from '../components/DataDrivenInsights';
import AIPrioriDiff from '../components/AIPrioriDiff';
import RequestAccess from '../components/RequestAccess';
import MailChimpForm from '../components/MailChimpForm';
import { useEffect } from 'react';

function Home() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <HomepageHeader />
      <div className='maxWidth page-margin'>
        <HeroSection />
        <CallToAction />
        <DataDrivenInsights />
        <AIPrioriDiff />
        <RequestAccess />
        <MailChimpForm />
      </div>
      <HomepageFooter />
    </div>
  );
}

export default Home;
