import React from "react";
import "../styles/requestAccess.scss";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <section className="request-access">
      <div className="request-access-circle show-desktop"></div>
      <div className="request-access-triangle show-desktop"></div>
      <div className="request-acesss-content">
        <h2 className="request-access-content">
          Ready to Increase Your Impact?
        </h2>
        <p className="request-access-content">
          Sign up to try out and explore this new tool at no cost.
        </p>

        <Button
          id="request-access-early-access-btn"
          variant="contained"
          onClick={() => navigate("/joinus")}
        >
          Request Early Access
        </Button>
      </div>
    </section>
  );
};

export default HeroSection;
