import React from 'react';
class SubscribeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
    }

    render() {
        return (
            <form className='subscribe-form' onSubmit={this.handleSubmit}>
                <label className='subscribe-form'>
                    <input placeholder='type email here' type="text" value={this.state.value} onChange={this.handleChange} />
                </label>
                <input className='subscribe-form' type="submit" value="Submit" />
            </form>
        );
    }
}
export default SubscribeForm;