import React from 'react';
import '../styles/meetAnna.css';
import { useNavigate } from "react-router-dom";
//Paths
const docImg = "/img/AdobeStock_411824043.jpg";

const MeetAnna = () => {
    return (
        <div className="meetAnna">
            <div className='meetAnna-content'>
                <h2>Meet Anna</h2>
                <h2>Foundation, Program Officer</h2>
                <p>Anna is a program officer for the “DoGood Foundation,” which invests $15 million annually to address food insecurity, climate change, and healthy housing in the US. Anna’s goal is to support her grantees, identify innovative solutions, and scale proven strategies aligned with the foundation’s areas of focus. While reviewing progress reports from her current awardees she starts to notice patterns around their successes and failures.</p>
                <p>Suspecting she may be on to something, she expands her search and starts to dig through thousands of case studies, evaluations, and assessments the foundation has archived over the past 20 years. Anna begins to see shared learnings across programs, failures that have been unnecessarily repeated, and opportunities for new strategic investments that have not been captured elsewhere.</p>
                <p>Realizing there are more documents than her team can review manually, Anna uses AI PRIORI&trade; to quickly group and review everything. For example, in one batch, Anna uploaded all 500 grantee progress reports that had been submitted over the program's 8-year history. The team asked AI PRIORI to review the content for enabling and impeding programmatic factors. Within minutes they had the most relevant matches on their screens. Using this streamlined information, Anna and her team were able to quickly make strategic updates, redirect funding, and identify the right partners for the next iteration of the program.
                </p>
                <p className='italic'>
                    Sample use case intended for illustrative purposes only. Names and other identifiable information have been altered.</p>
            </div>
            <div>
                <img
                    className='imgborder meetAnna'
                    smooth="true"
                    src={docImg}
                    alt="">
                </img>
            </div>
        </div >
    )
}

export default MeetAnna;