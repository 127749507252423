import '../App.css';
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from '../components/HomepageFooter';
import DataPartner from "../components/DataPartner";
import BenefitsForDataPartners from "../components/BenefitsForDataPartners";
import { useEffect } from 'react';

function Resources() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <HomepageHeader />
      <div className='maxWidth'>
        <DataPartner />
        <BenefitsForDataPartners />
      </div>
      <HomepageFooter />
    </div>
  );
}

export default Resources;
