import React from 'react';
import '../styles/generic-page.scss';

const PrivacyPolicy = () => {
    const privacyPolicyUrl = "https://d1iikonv94kyk.cloudfront.net/AIP_Privacy_Policy.pdf";
    return (
        <div className="pdf-page-container">
            <object data={privacyPolicyUrl} type="application/pdf" width="100%" height="100%">
            </object>
        </div>
    )
}

export default PrivacyPolicy;