import React from "react";
import "../styles/beFeatured.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
//Paths

const BeFeatured = () => {
  const navigate = useNavigate();
  return (
    <div className="be-featured">
      <div className="be-featured-circle show-desktop"></div>
      <div className="be-featured-triangle show-desktop"></div>
      <div className="request-acesss-content">
        <h2 className="be-featured-content">Be Featured in a Case Study</h2>
        <p className="be-featured-content">
          We are currently looking for new and interesting use cases in which AI
          PRIORI can help. If you have a social sector challenge related
          to information and content review, we invite you to send us a note.
        </p>
        <Button
          id="be-featured-get-in-touch-btn"
          variant="contained"
          onClick={() => navigate("/contactus")}
        >
          Get In Touch
        </Button>
      </div>
    </div>
  );
};

export default BeFeatured;
