import "../App.css";
import HomepageHeader from "../components/HomepageHeader";
import HomepageFooter from "../components/HomepageFooter";
import InforToInsights from "../components/InfoToInsights";
import { useEffect } from "react";

function Labs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <HomepageHeader />
      <div className="maxWidth">
        <InforToInsights />
      </div>
      <HomepageFooter />
    </div>
  );
}

export default Labs;
