import React from "react";
import "../styles/infoOverload.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
//Paths
const tackleImg3 = "/img/AdobeStock_87429935.jpg";
const tackleImg2 = "/img/AdobeStock_542878338.jpg";
const tackleImg1 = "/img/AdobeStock_602283024.jpg";

const InfoOverload = () => {
  const navigate = useNavigate();
  return (
    <div className="info-overload">
      <div className="info-overload-img-grp">
        <img
          className="imgborder tackle-img1"
          smooth="true"
          src={tackleImg1}
          alt=""
        ></img>
        <img
          className="imgborder tackle-img2"
          smooth="true"
          src={tackleImg2}
          alt=""
        ></img>
        <img
          className="imgborder tackle-img3"
          smooth="true"
          src={tackleImg3}
          alt=""
        ></img>
      </div>
      <div className="info-overload-content">
        <h2>Tackle Information Overload</h2>
        <p>
          Complex issues such as food insecurity, climate action, and affordable
          housing require comprehensive solutions. To find those big ideas and
          translate them into action, changemakers working at foundations,
          nonprofits, and other sectors must keep pace with the latest news and
          developments.
        </p>
        <p>
          This unending flow of information, or information overload, poses a
          real problem for changemakers, because there is simply more
          information than any one person, team, or organization can consume.
          The result is a knowledge gap that reduces organizational impact and
          efficiency.
        </p>
        <ul>
          <li>
            Only 34% of nonprofits report that they always make decisions based
            on data and evidence (Salesforce, 2021)
          </li>
          <li>
            Only 1 in 20 nonprofit organizations have leadership who know how to
            and are actively leveraging data for more effective outcomes (Data
            Orchard, 2021)
          </li>
        </ul>
        <p>
          These challenges are mainly attributed to lack of time, resources, and
          capacity. (Nonprofit Technology Trends, 2021)
        </p>
        <p>
          AI PRIORI® is designed specifically to help changemakers address
          information overload and the resulting knowledge gaps it creates.
          Users can effectively harness the content they already have and
          augment their own knowledge and experiences with data driven insights
          that would otherwise remain buried.
        </p>
        <br></br>
        <Button
          id="info-over-request-early-access-btn"
          variant="contained"
          onClick={() => navigate("/joinus")}
        >
          Request Early Access
        </Button>
      </div>
    </div>
  );
};

export default InfoOverload;
