import { Button, TextField, ThemeProvider, createTheme } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import "../styles/joinUs.scss";
import GetCurrentDate from "./utils/GetCurrentDate";
import GuidGenerator from "./utils/GuidGenerator";

//Paths
const backgroundImg = "/img/aipBackgroundImg.jpg";
const API_KEY = process.env.REACT_APP_EAR_API_KEY;
const API_URL =
  "https://bpuoak20j8.execute-api.us-east-1.amazonaws.com/prod/ear";

const darkFormTheme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          backgroundColor: "#FF0000",
        },
      },
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          padding: "8px 16px",
        },
      },
      underline: {
        "&:after": {
          borderBottom: `2px solid #FFFFFF`,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: "8px 16px",
          color: "rgba(255, 255, 255, 0.70)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "#ffffff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: "10px 24px",
          fontSize: "14px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "#47246B",
          },
        },
      ],
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
  },
});

const JoinUs = () => {
  const [post, setPost] = React.useState(null);
  const [username, setusername] = useState("");
  const [orginization, setorganization] = useState("");
  const [email, setemail] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  let handleSubmit = async (e) => {
    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Please enter a valid email address.");
      e.preventDefault();
      return;
    }

    var uuid = GuidGenerator();
    var date = GetCurrentDate();
    e.preventDefault();
    axios
      .post(
        API_URL,
        {
          earId: uuid,
          username: username,
          organization: orginization,
          firstname: firstname,
          lastname: lastname,
          email: email,
          requestdate: date,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setusername("");
          setfirstname("");
          setemail("");
          setlastname("");
          setorganization("");
          setemail("");
          setPost("");
          alert(
            "Thank you for your interest in AI PRIORI®!\n\nWe are granting access to new users each week as we balance interest in the tool at this early stage with our efforts to respond to user feedback and improve the user experience. We’ll notify you in a few weeks via email when you have access."
          );
        }
      })
      .catch((res) => {
        if (res.status != 200) {
          alert(
            "Something went wrong. Please contact us if you continue to have issues."
          );
        }
      });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="join-us">
      <div className="join-us-content">
        <h2>Early Access Request</h2>
        <ThemeProvider theme={darkFormTheme}>
          <form
            onSubmit={handleSubmit}
            name="join-us-form"
            className="join-us-content"
          >
            <fieldset className="join-us-content">
              <TextField
                label="Username"
                variant="standard"
                name="username"
                value={username}
                required
                onChange={(e) => setusername(e.target.value)}
              />
              <TextField
                label="Organization"
                variant="standard"
                name="orginization"
                value={orginization}
                required
                onChange={(e) => setorganization(e.target.value)}
              />
              <TextField
                label="First Name"
                variant="standard"
                name="firstName"
                value={firstname}
                required
                onChange={(e) => setfirstname(e.target.value)}
              />

              <TextField
                label="Last Name"
                variant="standard"
                name="lastName"
                value={lastname}
                required
                onChange={(e) => setlastname(e.target.value)}
              />

              <TextField
                label="Email"
                variant="standard"
                name="email"
                value={email}
                required
                error={!!emailError}
                helperText={emailErrorText}
                onChange={(e) => {
                  setemail(e.target.value);
                  setEmailError(false);
                  setEmailErrorText("");
                }}
              />
            </fieldset>

            <Button id="join-us-join-us-btn" type="submit" variant="contained">
              Join Us
            </Button>
          </form>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default JoinUs;
