import React from 'react';
import '../styles/closingGap.css';

const ClosingGap = () => {
    return (
        <div className="closing-gap">
            <div className='closing-gap-content'>
                <h2>Closing the Knowledge Gap</h2>
                <div className='closing-gap-card-grp'>
                    <div className='closing-gap-card'>

                        <div className='quote'>
                            <p className='closing-gap-card-text'>“The amount of information is paralyzing and too much. I'm not spending the time I should be [reading everything], but I have no other choice, I'm too busy.”</p>
                        </div>

                        <div className='title-block'>
                            <p className='closing-gap-card-title'>Program Officer</p>
                            <p className='closing-gap-card-org'>Private Foundation</p>
                        </div>
                    </div>
                    <div className='closing-gap-card'>
                        <div className='quote'>
                            <p className='closing-gap-card-text'>“I only look at about 10% of the information I receive everyday, it’s overwhelming.”</p>
                        </div>
                        <div className='title-block'>
                            <p className='closing-gap-card-title'>Executive</p>
                            <p className='closing-gap-card-org'>Nonprofit Organization</p>
                        </div>
                    </div>
                    <div className='closing-gap-card'>
                        <div className='quote'>
                            <p className='closing-gap-card-text'>“Information consumption is a learning opportunity for us - to see best practices and help us stay connected, stay excited about the work we are doing.”</p>
                        </div>

                        <div className='title-block-lg'>
                            <p className='closing-gap-card-title'>Learning and Evaluation Manager</p>
                            <p className='closing-gap-card-org'>Community Foundation</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ClosingGap;