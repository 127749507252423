import React from "react";
import "../styles/before-leaving-popup.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

function BeforeLeavingPopup(props) {
  const onClose = () => {
    if (props.closePopup) props.closePopup();
  };
  return (
    <div className="before-leaving-popup">
      <div className="before-leaving-container">
        <div className="bl-popup">
          <div className="image-container"></div>
          <div className="content-container">
            <h3>Before you go...</h3>
            <p>
              Sign up for a free trial and see how you can turn your information
              into insights.
            </p>
            <Box component="form" noValidate autoComplete="off">
              <TextField label="Email Address" variant="standard" />
              <Button
                id="before-leaving-start-free-trial-btn"
                variant="contained"
              >
                Start free trial
              </Button>
            </Box>
            <button className="close-popup" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeforeLeavingPopup;
