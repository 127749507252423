import React from "react";
import "../styles/aiPrioriDiff.scss";

const AIPrioriDiff = () => {
  const incEffimg = "/img/IncreasedEfficincy.png";
  const commCentered = "/img/communitycentered.png";
  const sysChange = "/img/SystemsChange.png";
  return (
    <section className="aip-diff">
      <div className="aip-diff-circle show-desktop"></div>
      <div className="aip-diff-black-triangle show-desktop"></div>
      <div className="aip-diff-red-triangle show-desktop"></div>

      <div className="aip-diff-card-grp">
        <h2 className="aip-diff-title">
          The AI PRIORI <b>Difference</b>
        </h2>
        <div className="aip-diff-card">
          <div className="heading">
            <div className="api-diff-card-icon">
              <img smooth="true" src={incEffimg} alt=""></img>
            </div>
            <h3>Increased Efficiency</h3>
          </div>
          <p>
            AI PRIORI is designed to help social sector leaders identify and
            consume more relevant content than ever before—saving them valuable
            time and resources, and positioning them to make more effective
            decisions.
          </p>
        </div>
        <div className="aip-diff-card">
          <div className="heading">
            <div className="api-diff-card-icon">
              <img smooth="true" src={commCentered} alt=""></img>
            </div>
            <h3>Community-centered</h3>
          </div>
          <p>
            Working with community, nonprofit, and issue area leaders from
            across the US, AI PRIORI curates proprietary data sets on key issues
            and makes them available to users who want to generate responses
            that take into account diverse backgrounds and lived experiences.
          </p>
        </div>
        <div className="aip-diff-card">
          <div className="heading">
            <div className="api-diff-card-icon">
              <img smooth="true" src={sysChange} alt=""></img>
            </div>
            <h3>Systems Change Focused</h3>
          </div>
          <p>
            With AI PRIORI's custom lenses, you can seamlessly integrate your
            own definitions, frameworks, and concepts into your queries–so you
            always get the most relevant responses.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AIPrioriDiff;
