import React, { Component } from 'react';
import '../styles/pageHeroSection.scss';

class PageHeroSection extends Component {

    render() {
        return (
            <section className={`page-hero-section ${this.props.centered ? 'center' : ''} ${this.props.className}`}>
                <div className="heading-container">
                    <h1>{this.props.title}</h1>
                </div>
            </section>
        )
    }
}

export default PageHeroSection;